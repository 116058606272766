import React, { useEffect, useState }  from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';


import { AppWrap } from '../../wrapper';
import { images } from '../../Constants';
import './Header.scss';

const headerStyle = {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  flex: '1',
}

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut',
    }
  }
}


const Header = () => {
  const [ref, isInView] = useInView({
    triggerOnce: false,
  });

  const stuff = ['YouTube Videos', 'Custom Web Design', 'Google Ads', 'Social Content', 'Product Photography', 'Email Marketing', 'Print Ads', 'Branding', 'Logo Design'];
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isInView) {
        setIndex((prevIndex) => (prevIndex + 1) % stuff.length);
      }
    }, 2000); // Change this to adjust the speed of the loop

    return () => {
      clearInterval(interval);
    };
  }, [isInView, stuff.length]);

  return (
    <>
      <div id= "Who is Don?" style={headerStyle}>
        <div id= "home">
          <div className='app__flex'>
            <motion.div
            whileInView={{ y: [-50, 0], opacity: [0, 0, 1] }}
              transition={{ duration: 1.5 }}
              ease='easeInOut'
              className='app__header-info'
            >
              <div className='app__header-badge'>
                <div className='badge-cmp app__flex'>
                  <motion.div className='badge-cmp app__flex'
                    initial= {{ opacity: 1}}
                    animate={{ opacity: [1, 0]}}
                    transition={{
                      delay: 4,
                    }}
                  >
                  <motion.span
                      animate={{ rotate: [100, 0],
                      ease: "easeInOut",
                      }}
                      transition={{
                      delay: .75,
                        type: "spring",
                        stiffness: 200,
                        damping: 20,
                        repeat: 4,
                        repeatType: "reverse",
                        duration: 3,
                      }}
                      >
                        👋
                  </motion.span>
                  </motion.div>
                  <motion.div
                  initial={{ x: 0 }}
                  animate={{ scale: [1, 1.1], x: -80 }}
                  transition={{
                    delay: 4.3,
                  }}
                  >
                    <p className='p-text'>Hey! My name is</p>
                    <h1 className='head-text'>Donovan</h1>
                  </motion.div>
                </div>
                <div className='tag-cmp app__flex'>
                  <p className='p-text'>I make stuff to get your business noticed like:</p>
                  <div ref={ref}>
                    <p className='stuff-tag'>
                      <AnimatePresence mode="wait">
                        <motion.span
                          className='stuff-tag'
                          key={stuff[index]}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                        >
                          {stuff[index]}
                        </motion.span>
                      </AnimatePresence>
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>  
          
          <div className= 'app__header app__flex app__center-head'>   
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 1.5, delayChildren: 1 }}
              ease='easeInOut'
              className='app__header-img'
              >
                <div className='app__profile-group'>
                <a href='#emailLink'>
                    <motion.div
                    while whileHover={{ scale: 1.1, ease: 'easeInOut' }}
                    >
                      <h1>
                        Click here to get in touch!
                      </h1>
                    </motion.div>
                  </a>
                  <img src={images.profile} alt='profile-bg' />
                </div>
                <a href='#work'> 
                  <motion.div
                  variants={scaleVariants}
                  whileInView={ scaleVariants.whileInView }
                  className='app__header-circles app__flex'
                  >
                    {[images.vm, images.react, images.target].map((circle, index) => (
                      
                        <motion.div className='circle-cmp app__flex' key={`circle-${index}`}
                        while whileHover={{ scale: 1.1, ease: 'easeInOut' }}
                        >
                        <img src={circle} alt='circle' />
                        </motion.div>
                      
                    ))}
                  </motion.div>
                </a>
             </motion.div>

          </div>
        </div>  
      </div>
    </>
  )
}

export default AppWrap(Header);