import React from 'react'
import {BsFacebook } from 'react-icons/bs';
import { MdOutlineEmail } from "react-icons/md";


const SocialMedia = () => {

  return (
    <div className='app__social'>
        <a href='#emailLink'>
          <div>
           <MdOutlineEmail />
          </div>
        </a>
        {/* <a href="yourLink"> 
          <div>
              <BsYoutube/>
          </div>
        </a> */}
        <a href='https://www.facebook.com/Donovanallenmedia' target="_blank" rel="noreferrer">
          <div>
              <BsFacebook/>
          </div>
        </a>
    </div>   
  )
}

export default SocialMedia