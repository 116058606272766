import email from '../assets/email.png';
import mobile from '../assets/mobile.png';

import react from '../assets/react.png';

import about01 from '../assets/about01.jpg';
import about02 from '../assets/about02.jpg';

import profile from '../assets/profile.png';
import circle from '../assets/circle.svg';
import logo from '../assets/logo.png';

import vm from '../assets/vm.png';
import target from '../assets/target.png';

export default {
  email,
  mobile,

  react,

  about01,
  about02,

  profile,
  circle,
  logo,

  vm,
  target,
};