import { createClient } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export const client = createClient({
    projectId: '5rep9yu6',
    dataset: 'production',
    apiVersion: '2023-05-03',
    useCdn: true, 
    token: 'skoguhWDJZtTM7ThgVu1pWBJ8qmzKTQQmq78szD25gzxjSesM8i3MJ6h1h9nZkLHfyN1dcB8qpiqhmmD6rl7UqIpJYKnSDvPRXdYzwyIBRpdv5TKAgq2LsaAimbmsyuXG45tP0uIliagaF2LQeJqqeLXqdZ7WIpnYcyVjggQuoOVPhIH0wur',
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);